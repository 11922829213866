<template>
  <div class="document-editor-grid-container" v-if="document">
    <div v-if="pages.length > 0" class="document">
      <DocumentPage2
        :fields="pageFields"
        :image="page.path"
        @drag-resize:resize="onFieldResize"
        @document-page:delete="onDeleteField($event.fieldNumber)"
      />
    </div>
    <div v-else class="document">Es wurden noch keine Seiten hochgeladen!</div>
    <div class="options">
      <b-row>
        <b-col class="page-select">
          <a @click.prevent="onPreviousPage"><span class="pi pi-fw pi-chevron-left"></span></a>
          Seite {{ currentPage }} / {{ pages.length }}
          <a @click.prevent="onNextPage"><span class="pi pi-fw pi-chevron-right"></span></a>
        </b-col>
        <b-col class="mb-2 ">
          <router-link
            class="btn btn-success right"
            :to="{
              name: 'ProjectDocumentPrintPage',
              params: { projectNumber: previewProjectNumber, documentNumber: document.number },
            }"
            target="_blank"
          >
            <i class="fa fa-file mr-2"></i>{{ $t('preview') }}
          </router-link>
          ({{ $t('project') }}
          <InputNumber
            inputClass="small"
            inputStyle="width:50px;text-align:center"
            v-model="previewProjectNumber"
          />
          )
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="5" md="5" sm="5">
          <Tree
            :value="fields"
            selectionMode="single"
            @node-select="onTreeNodeSelect"
            :selectionKeys.sync="selectedTreeKey"
            style="min-height:50rem"
          >
            <template #default="slotProps">
              {{ $t(slotProps.node.label) }}
            </template>
          </Tree>
        </b-col>
        <b-col lg="1" md="1" sm="2">
          <button
            @click="onAddField"
            type="button"
            class="p-button p-component p-button-icon-only mt-4"
          >
            <span class="pi pi-angle-right p-button-icon"></span
            ><span class="p-button-label"> </span>
          </button>
        </b-col>
        <b-col lg="6" md="6" sm="6">
          <Listbox
            v-model="selectedNetRegistrationExportDocumentField"
            :options="pageFields"
            optionLabel="number"
            style="min-height:50rem"
          >
            <template #option="slotProps">
              <div class="align-items-center list-group-grid-container">
                <div>#{{ slotProps.option.number }}</div>
                <div>
                  {{ $t(slotProps.option.label) }}
                  <!-- <span v-if="slotProps.option.value">- {{ slotProps.option.value }}</span> -->
                  <!-- (x:{{ slotProps.option.x }},w:{{slotProps.option.width}},w:{{ slotProps.option.width }},h:{{ slotProps.option.height }}) -->
                </div>
                <div>
                  <b-button
                    @click.prevent="onDeleteField(slotProps.option.number)"
                    variant="outline-danger"
                  >
                    <i class="fa fa-sm fa-times"></i>
                  </b-button>
                </div>
              </div>
            </template>
          </Listbox>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DocumentPage2 from '@/components/Document/DocumentPage2';
import Listbox from 'primevue/listbox';
import Tree from 'primevue/tree';
import InputNumber from 'primevue/inputnumber';

export default {
  name: 'DocumentEditor',
  components: {
    DocumentPage2,
    Tree,
    Listbox,
    InputNumber,
  },
  props: {
    document: { type: Object },
  },
  data() {
    return {
      selectedDocumentField: null,
      selectedNetRegistrationExportDocumentField: null,
      selectedTreeKey: null,
      currentPage: 1,
      previewProjectNumber: 394,
      augmentedFields: [],
    };
  },
  computed: {
    ...mapGetters(['getNetOutputStates', 'getDocumentFields']),

    fields() {
      return this.getDocumentFields;
    },
    pages() {
      return this.document.pageBackgroundAttachments;
    },
    page() {
      return this.document.pageBackgroundAttachments[this.currentPage - 1];
    },
    pageFields() {
      return this.document.fields.filter((field) => field.page == this.currentPage);
    },
  },
  methods: {
    ...mapActions([
      'fetchPowerCompanies',
      'addDocumentField',
      'deleteDocumentField',
      'updateDocumentField',
      'fetchNetOutputStates',
      'initDocumentFields',
    ]),
    onFieldResize(updatedField) {
      const existingField = this.document.fields.find(
        (existingField) => existingField.number == updatedField.number
      );
      if (existingField) {
        Object.assign(existingField, updatedField);
      }
    },
    getItems() {
      return this.exportDocumentFields;
    },
    onDeleteField(fieldNumber) {
      const fieldIndex = this.document.fields.findIndex(
        (existingField) => existingField.number == fieldNumber
      );
      if (fieldIndex >= 0) {
        this.document.fields.splice(fieldIndex, 1);
      }
    },
    onAddField() {
      const newField = Object.assign(
        { x: 60, y: 70, width: 180, height: 18, page: this.currentPage },
        this.selectedDocumentField
      ); // make a copy
      this.document.fieldCounter += 1;
      newField.number = this.document.fieldCounter;
      this.document.fields.push(newField);
    },
    onTreeNodeSelect(node) {
      this.selectedDocumentField = node;
    },
    onPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    onNextPage() {
      if (this.currentPage < this.pages.length) {
        this.currentPage++;
      }
    },
  },
  async mounted() {
    await this.initDocumentFields();
  },
};
</script>

<style scoped>
::v-deep .p-inputnumber-buttons-stacked .p-inputnumber-input {
  width: 3em;
}
::v-deep .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0.1rem;
}
::v-deep .p-tree .p-treenode-children {
  padding: 0 0 0 0.8rem;
}
.page-select {
  margin-bottom: 1em;
}
.document-editor-grid-container {
  display: grid;
  margin-top: 1em;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
}
.list-group-grid-container {
  display: grid;
  grid-template-columns: 35px 4fr 30px;
}
::v-deep .p-listbox .p-listbox-list .p-listbox-item {
  padding: 0.3rem 0.8rem;
}
.list-group-grid-container button {
  padding: 0 3px;
  height: 23px;
  width: 23px;
}
</style>

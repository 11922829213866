<template>
  <div v-if="document" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <b-tabs content-class="" fill>
      <b-tab title="Stammdaten">
        <b-row>
          <b-col lg="6" md="6" sm="12">
            <legend>
              <strong>Basisdaten</strong>
            </legend>

            <b-form-group
              horizontal
              label="#"
              label-for="number"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <div v-if="documentNumber && documentNumber !== '_new'" class="form-text">
                {{ document.number.toString().padStart(4, '0') }}
              </div>
              <div v-else class="form-text">N/A</div>
            </b-form-group>

            <b-form-group
              horizontal
              :label="$t('name')"
              label-for="name"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="document.name" type="text" :placeholder="$t('name')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('approvedState')"
              label-for="approvedState"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('approvedState')"
                v-model="document.approvedState"
                :options="getEnumValues('DocumentApprovedState')"
                :show-labels="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><b-badge :variant="documentApprovedStateColor(option)">{{
                    $t(option)
                  }}</b-badge></template
                >
                <template slot="option" slot-scope="{ option }">
                  <b-badge :variant="documentApprovedStateColor(option)">
                    {{ $t(option) }}
                  </b-badge></template
                >
              </multiselect>
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('documentType')"
              label-for="documentType"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('documentType')"
                v-model="document.type"
                :options="getEnumValues('DocumentType')"
                :show-labels="false"
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ $t(option) }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ $t(option) }}
                </template>
              </multiselect>
            </b-form-group>

            <b-form-group
              horizontal
              :label="$t('powerCompanyAssignment')"
              label-for="documentType"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <Listbox
                v-if="
                  document.type == 'NET_REGISTRATION' || document.type == 'OPERATION_REGISTRATION'
                "
                v-model="selectedPowerCompanies"
                :options="getPowerCompanies"
                :multiple="true"
                :filter="true"
                optionLabel="company"
                dataKey="number"
                listStyle="max-height:400px"
                style="width:100%"
              >
              </Listbox>
              <div class="pt-1" v-else><small>N/A</small></div>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <legend>
              <strong>Seiten hochladen</strong>
            </legend>

            <b-row v-if="documentNumber">
              <b-col lg="4" md="4" sm="12">
                <DropzoneUpload
                  acceptedFileTypes="image/*"
                  :complete="isAttachmentUploadComplete"
                  :parentId="document.id"
                  parentType="pageBackgroundAttachments"
                  :uploadCallback="uploadDocumentAttachment"
                  v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
                />
              </b-col>
              <b-col lg="8" md="8" sm="4">
                <AttachmentList
                  :attachments="document.pageBackgroundAttachments"
                  attachmentType="pageBackgroundAttachments"
                  v-on:attachment-list:delete="onDeleteDocumentAttachment"
                ></AttachmentList>
              </b-col>
            </b-row>
            <b-row v-if="!documentNumber">
              <b-col>
                <small>Neues Dokument zunächst speichern!</small>
              </b-col>
            </b-row>

            <legend>
              <strong>{{ $t('settings') }}</strong>
            </legend>

            <b-form-group
              horizontal
              :label="$t('mailConstraintState')"
              label-for="mailConstraintState"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('select')"
                v-model="document.mailConstraintState"
                :options="getEnumValues('MailConstraintState')"
                :show-labels="false"
                :allow-empty="false"
                :selectLabel="$t('select')"
                :selectedLabel="$t('selected')"
                :deselectLabel="$t('remove_selection')"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ $t(option) }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ $t(option) }}
                </template>
              </multiselect>
            </b-form-group>

            <legend>
              <strong>{{ $t('notes') }}</strong>
            </legend>
            <b-form-group horizontal breakpoint="md">
              <ckeditor
                :editor="editor"
                v-model="document.description"
                :config="editorConfig"
              ></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Seiten bearbeiten" active>
        <DocumentEditor v-if="!isNew" :document="document" />
      </b-tab>
    </b-tabs>

    <b-row>
      <b-col lg="12" md="12" sm="12">
        <ButtonBar
          :showDeleteButton="buttons.showDeleteButton"
          :model="document"
          modelEditRoute="DocumentEditPage"
          modelListRoute="DocumentsPage"
          modelRouteParamName="documentNumber"
          :updateCallback="updateDocument"
          :createCallback="createDocument"
          :deleteCallback="deleteDocument"
          :fetchCallback="fetchDocuments"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import DocumentEditor from '@/components/Document/DocumentEditor';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import Multiselect from 'vue-multiselect';
import Listbox from 'primevue/listbox';
import { yesNo } from '@/helpers/enums';
import { getYesNoStateColor, getDocumentApprovedStateColor } from '@/helpers/colors';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'DocumentEditPage',
  components: {
    Breadcrumbs,
    DropzoneUpload,
    AttachmentList,
    DocumentEditor,
    Multiselect,
    Listbox,
    ckeditor: CKEditor.component,
    ButtonBar,
  },
  props: {
    documentNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
      isAttachmentUploadComplete: false,
      editor: InlineEditor,
      editorConfig: {},
    };
  },
  computed: {
    ...mapGetters(['getDocument', 'getDocumentByNumber', 'getPowerCompanies', 'getEnumValues']),
    buttons() {
      return {
        showDeleteButton: this.document && this.document.number !== '_new' ? true : false,
      };
    },
    document() {
      return this.getDocumentByNumber(this.number);
    },
    isNew() {
      return this.document.id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('document'), route: { name: 'DocumentsPage' } },

        {
          name:
            this.documentNumber && this.documentNumber !== '_new'
              ? 'Dokument ' + this.documentNumber + ' bearbeiten'
              : 'erstellen',
        },
      ];
    },
    selectedPowerCompanies: {
      get() {
        if (this.document.type === 'NET_REGISTRATION') {
          return this.document.netRegistrationPowerCompanies;
        }
        if (this.document.type === 'OPERATION_REGISTRATION') {
          return this.document.operationRegistrationPowerCompanies;
        }
        return null;
      },
      set(powerCompanies) {
        if (this.document.type === 'NET_REGISTRATION') {
          this.document.netRegistrationPowerCompanies = powerCompanies;
        }
        if (this.document.type === 'OPERATION_REGISTRATION') {
          this.document.operationRegistrationPowerCompanies = powerCompanies;
        }
      },
    },
    optionsYesNo() {
      return yesNo();
    },
  },
  methods: {
    ...mapActions([
      'initDocument',
      'fetchDocuments',
      'fetchDocumentByNumber',
      'updateDocument',
      'createDocument',
      'deleteDocument',
      'uploadDocumentAttachment',
      'deleteDocumentAttachment',
      'fetchPowerCompanies',
      'fetchEnumValues',
    ]),
    onDeleteDocumentAttachment(event) {
      this.deleteDocumentAttachment({
        documentId: this.document.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    documentApprovedStateColor(state) {
      return getDocumentApprovedStateColor(state);
    },
  },
  async mounted() {
    if (!this.document && this.documentNumber) {
      this.number = this.documentNumber;
      await this.fetchDocumentByNumber(this.number);
    } else {
      this.initDocument();
      this.number = '_new';
    }
    await this.fetchPowerCompanies();
    await this.fetchEnumValues('MailConstraintState');
    await this.fetchEnumValues('DocumentType');
    await this.fetchEnumValues('DocumentApprovedState');

    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadDocumentAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.document-editor-grid-container {
  display: grid;
  margin-top: 1em;
  grid-template-columns: 1fr 1fr;
}
</style>

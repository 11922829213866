<template>
  <div class="page A4" :style="backgroundStyles(image)">
    <vue-draggable-resizable
      v-for="(field, index) in displayFields"
      class-name="draggable-resizable-field"
      class-name-active="draggable-resizable-field-active"
      class-name-resizing="draggable-resizable-field-resizing"
      class-name-dragging="draggable-resizable-field-dragging"
      :class="{ 'draggable-resizable-field-checkbox': !isResizable(field) }"
      :resizable="isResizable(field)"
      :parent="true"
      :key="index"
      :w="field.width"
      :h="field.height"
      :x="field.x"
      :y="field.y"
      :minHeight="minHeight"
      :grid="grid"
      @resizing="
        (left, top, width, height) =>
          onResize(field, 'field' + field.number, left, top, width, height)
      "
      @dragging="(left, top) => onResize(field, 'field' + field.number, left, top)"
      :ref="'field' + field.number"
    >
      <div
        tabindex="-1"
        @keyup.delete="onDelete(field)"
        class="draggable-resizable-field-content"
        v-if="isResizable(field)"
      >
        <div class="fieldNumber">#{{ field.number }}</div>
        <div class="freeText" v-if="isFreetext(field)">
          <input v-model="field.value" type="text" placeholder="Freitext" />
        </div>
        <div v-else>
          <div>{{ $t(field.label) }}</div>
        </div>
        <!-- <div>x: {{ field.x }}</div>
        <div>y: {{ field.y }}</div>
        <div>w: {{ field.width }}</div>
        <div>h: {{ field.height }}</div> -->
      </div>
      <div v-else tabindex="-1" @keyup.delete="onDelete(field)">x</div>
    </vue-draggable-resizable>
  </div>
</template>
<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
export default {
  name: 'DocumentPage2',
  components: {
    VueDraggableResizable,
  },
  props: {
    fields: Array,
    image: String,
  },
  data() {
    return {
      grid: [2, 2],
      minHeight: 18,
      minwidth: 15,
      resizable: true,
      inMotion: false,
      computedFields: [],
    };
  },
  computed: {
    displayFields() {
      return this.computedFields;
    },
  },
  methods: {
    isResizable(field) {
      if (
        field.name === 'common.selection' ||
        field.type === 'CHECKBOX' ||
        field.type === 'CHECKBOX_INVERTED' ||
        field.type === 'SPECIAL'
      ) {
        return false;
      }
      return true;
    },
    /**
     * Fields of type freeText show a text input
     */
    isFreetext(field) {
      return field.name === 'common.freeText' ? true : false;
    },
    onResize(field, ref, left, top, width, height) {
      field.x = left;
      field.y = top;
      field.width = width ? width : field.width;
      field.height = height ? height : field.height;
      // console.log('onResize', field.x, field.y);
    },
    onDelete(field) {
      this.$emit('document-page:delete', {
        fieldNumber: field.number,
      });
    },
    backgroundStyles(image) {
      return {
        'background-image': `url(${image})`,
      };
    },
  },
  mounted() {
    this.computedFields = this.fields;
  },
  watch: {
    fields(newFields, oldFields) {
      if (newFields) {
        this.computedFields = [];
        // console.log('old', oldFields[0].number, oldFields[0].x, oldFields[0].y);
        // console.log('new', newFields[0].number, newFields[0].x, newFields[0].y);
        this.$nextTick(() => {
          this.computedFields = newFields;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/** DIN A4 Page Format */
.A4 {
  background: white;
  background-size: cover;
  background-repeat: no-repeat;
  width: 21cm;
  // width: auto;
  height: 29.7cm;
  display: block;
  margin: 0;
  padding: 0;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow-y: none;
  box-sizing: border-box;
  margin-bottom: 1em;
}
.page {
  position: relative;
}
.draggable-resizable-field {
  background-color: rgba(255, 240, 50, 0.1);
  color: rgb(0, 0, 0);
  font-weight: 300;
  outline: 1px dashed #555;
  position: absolute;
}
.draggable-resizable-field-active,
.draggable-resizable-field-resizing,
.draggable-resizable-field-dragging {
  background-color: rgba(80, 255, 11, 0.589);
  outline: 1px solid rgb(255, 0, 0);
}
.draggable-resizable-field-checkbox {
  // background-color: rgba(6, 201, 55, 0.6);
  line-height: 9px;
  font-weight: bold;
  font-size: 14px;
  cursor: default;
  padding: 0 0 0 1px;
}

::v-deep .handle {
  width: 8px;
  height: 8px;
  background: #ccc;
  border: 1px solid #333;
}

.draggable-resizable-field-content {
  font-size: 11px;
  padding: 1px 8px;
  cursor: default;
  display: grid;
  grid-template-columns: 18px auto;
  column-gap: 2px;
  height: 100%;
}

.draggable-resizable-field-content div {
  display: inline-block;
  margin-right: 2px;
}
.freeText {
  min-width: 10px;
  margin: 0;
}

.freeText input {
  background-color: rgba(255, 255, 255, 0.9);
  width: 95%;
  padding: 0 5px;
  line-height: 14px;
  box-sizing: border-box;
  border: none;
  outline: none;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    background-size: cover !important;
    // height: 100%;
  }
  .noprint {
    display: none;
  }

  .enable-print {
    display: block;
  }
}
</style>
